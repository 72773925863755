import styled from "styled-components";

export const Grid = styled.div`
  text-align: center;
  position: relative;
  color: black;
  overflow: hidden;
  position: fixed;
`;

export const WidePictureFrame = styled.img`
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px){
        display: none;
    };   
`;
export const HighPictureFrame = styled.img`
  width: 100%;
  height: 100vh;
  top: -10%;
  @media screen and (min-width: 768px){
        display: none;
        width:0%;
        height: 0;
    };  
`;

export const PictureText = styled.h1`
  text-align: center;
  position: absolute;
  color: white;
  top: 40%;
  left: 15%;
  size: 4vw;
  font-family: 'Balsamiq Sans', cursive;
`;

export const InformationText = styled.span`
  text-align: center;
  position: absolute;
  color: white;
  top: 40%;
  left: 15%;
  size: 4vw;
  font-family: 'Balsamiq Sans', cursive;
`;
