import React from "react";
import Picutre from '../components/Picture'
import TotkBred from "../images/webb_totk.png"
import HighBackground from "../images/WEBB_TOT2021.png"



const bredapp = () => {
    return (
        <div>
            <Picutre wideBackground={TotkBred} highBackground={HighBackground}></Picutre>
        </div>
    )
}

export default bredapp;