import "./App.css";
import Bred from "./pages/bred";


function App() {
  return (
    <>
      <Bred></Bred>
    </>
    
  );
}

export default App;
