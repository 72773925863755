import React from "react";
import { WidePictureFrame, Grid, PictureText, HighPictureFrame} from "./PictureElement";

import Countdown from 'react-countdown';

const ToTK = new Date('2021-10-25T06:00:00');

const render = ({days, hours, minutes, seconds}) => {
  return ( <span>Biljettsläppet sker i skrivsalen om {days} dagar {hours} timmar {minutes} minuter och {seconds} sekunder!!</span>
    
  )
}

function Picutre({ wideBackground, highBackground }) {
  return (
    <Grid>
      <WidePictureFrame src={wideBackground} alt="WideBackgroundToTK"/>
      <HighPictureFrame src={highBackground} alt="Highbackground"/>
      <PictureText><Countdown date={ToTK} renderer={render}/></PictureText>
      
    </Grid>
  );
}


export default Picutre